import PropTypes from 'prop-types';
import React from 'react';
import BrandLogo from './brand-logo';
import './header.scss';
import LocalizedLink from './localized-link';
import { Trans } from 'react-i18next';
import Sticky from 'react-sticky-el';
import Icon from './icon';
import AppDownloadButton from './app-download-button';
import LanguageSwitcher from './language-switcher';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileNavigationActive: false,
    };
  }

  toggle = () => {
    this.setState(state => ({ isMobileNavigationActive: !state.isMobileNavigationActive }));
  };

  render() {
    return (
      <nav>
        <Sticky
          className={`navbar st-header ${this.state.isMobileNavigationActive && 'is-active'}
            ${this.props.hasDarkBackground && 'has-dark-bg'}`}
          role="navigation"
          aria-label="main navigation"
        >
          <div className="navbar-brand st-header__navbar-brand">
            <LocalizedLink to="/" className="navbar-item st-header__navbar-item">
              <BrandLogo className="st-header__logo" />
            </LocalizedLink>
          </div>

          <div
            className={`navbar-menu st-header__navbar-menu ${this.state.isMobileNavigationActive &&
              'is-active'}`}
          >
            <div className="navbar-start st-header__navbar-start">
              {this.props.menuLinks.map((item, index) => (
                <div className="navbar-item st-header__navbar-item" key={index}>
                  <LocalizedLink
                    to={item.link}
                    className="st-header__link"
                    activeClassName="is-active"
                    key={index}
                  >
                    <Trans i18nKey={item.name}></Trans>
                  </LocalizedLink>
                </div>
              ))}
            </div>
          </div>
        </Sticky>
      </nav>
    );
  }
}

Header.propTypes = {
  menuLinks: PropTypes.array,
  hasDarkBackground: PropTypes.bool,
};

Header.defaultProps = {
  menuLinks: [],
  hasDarkBackground: false,
};

export default Header;
