import React from 'react';
import PropTypes from 'prop-types';
import featherSprite from 'feather-icons/dist/feather-sprite.svg';

const Icon = ({ name, color, size, ...restProps }) => {
  const sizeStyle = {
    height: `${size}em`,
    width: `${size}em`,
  };

  return (
    <svg
      className="icon"
      xmlns="http://www.w3.org/2000/svg"
      // viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={sizeStyle}
      {...restProps}
    >
      <use xlinkHref={`${featherSprite}#${name}`} />
    </svg>
  );
};

export default Icon;

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
};

Icon.defaultProps = {
  color: 'currentColor',
  size: 1,
};
