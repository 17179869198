import React from 'react';
import PropTypes from 'prop-types';
import useAppDownloadButtons from '../hooks/use-app-download-buttons';
import { useTranslation } from 'react-i18next';

const AppDownloadButton = ({ type, width }) => {
  const appDownloadButtons = useAppDownloadButtons();
  const { t } = useTranslation();

  const button = appDownloadButtons.find(button => button.type === type);
  return (
    <a href={button.downloadUrl} title={t(button.title)}>
      <img width={width || 160} src={button.publicUrl} alt={t(button.title)} />
    </a>
  );
};

AppDownloadButton.propTypes = {
  type: PropTypes.string,
  width: PropTypes.number,
};

export default AppDownloadButton;
