import React from 'react';
import BrandLogo from './brand-logo';
import './footer.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';

const Footer = () => {
  const { t } = useTranslation('Footer');
  return (
    <footer className="st-footer">
      <div className="st-footer__main">
        <div className="container">
          <div className="st-footer__columns">
            <div className="st-footer__brand">
              <BrandLogo className="st-footer__logo" />

              <p className="st-footer__brand-info">
                IH Piamonte, Piamonte 23 - 28004 Madrid
                <br />
                🇪🇸 Spain
              </p>

              <div className="st-footer__social-media"></div>
            </div>

            <div className="st-footer__links">
              <div className="st-footer__column"></div>

              <div className="st-footer__column"></div>

              <div className="st-footer__column">
                <p className="title is-6 is-marginless has-text-white">{t('label.information')}</p>
                <ul className="st-footer__list">
                  <li>
                    <a href="mailto:info@stampit.com.es" className="st-footer__link">
                      {t('link.contactUs')}
                    </a>
                  </li>
                  <li>
                    <Link to="/privacy-policy" className="st-footer__link">
                      {t('link.privacyPolicy')}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
