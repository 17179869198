import React from 'react';
import { Link } from 'gatsby';

import { useTranslation } from 'react-i18next';
import { getLocalizedPath } from '../utils/i18n-utils';

const LocalizedLink = ({ to, children, ...props }) => {
  const { i18n } = useTranslation();
  const language = i18n.language;

  const path = `${getLocalizedPath(to, language)}`;

  return (
    <Link {...props} to={path}>
      {children}
    </Link>
  );
};

export default LocalizedLink;
