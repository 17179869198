import React from 'react';
import PropTypes from 'prop-types';

const BrandLogo = ({ className, style }) => (
  <svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 90">
    <path
          fill="#000"
          fillRule="evenodd"
          d="M31.6314082 1.08776562c22.8388745-4.20727645 37.2134189 4.1348178 40.8776107 19.07064918l-21.2584272 3.9151045c-2.1307816-5.3502532-7.1896241-7.5427117-16.4206592-5.8411022-9.3533316 1.722646-13.9629696 5.3198674-13.0857273 10.0273422.9242795 4.950562 6.6510491 6.0187427 15.6374912 6.3599996l12.5542077-.0631092c14.3674889.2267255 28.3304585 5.0206833 31.3925751 21.4430857C84.616374 73.6258863 67.767677 84.3497665 49.1786065 87.774022 22.4521139 92.6941981 7.59073532 83.0688845 3.89832126 66.6418074l21.13848254-3.8940681c2.5635231 7.0168022 8.3114594 10.2073201 20.9456303 7.8816312 9.1110904-1.6805732 14.9248787-4.8734285 13.890062-10.4270334-1.1947429-6.4020724-9.0640533-6.8228-17.9517175-7.3089742l-13.0833754-.0888203C14.0160061 52.1617643 2.89878242 46.4679168.44579653 33.3061537-2.59280154 17.0076322 10.1307399 5.04728023 31.6314082 1.08776562zm537.3570178.53292168C588.386535 1.6206873 600 13.0411055 600 30.1086236c0 17.1890616-11.36652 28.6094798-31.258519 28.6094798h-27.923588v28.8572417h-20.136591V1.6206873zm-375.756988-.00093495V20.0382737h-29.654553v67.5361364h-20.757482V20.0382737h-29.405257V1.61975235h79.817292zm192.118185-.00163616l30.642333 54.52162801h1.112428l30.395388-54.52162801h24.955545V87.572774h-20.263592V35.6339463h-1.110076L426.74229 77.7511208h-20.510537l-24.464007-42.1171745h-1.112428V87.572774h-20.26124V1.61811619h24.955545zM280.18285 1.61764871l37.930735 85.95465779h-22.239151l-7.90694-18.2946404h-40.402536l-7.909292 18.2946404h-22.361448L255.34725 1.61764871h24.8356zM268.320088 23.7245491h-1.234724l-12.3543 28.7310233h25.945676l-12.356652-28.7310233zm298.812723-4.1761894h-26.314918v21.2444087h26.070325c8.153885 0 11.735762-4.5438586 11.735762-10.6841448 0-5.8948618-3.704173-10.5602639-11.491169-10.5602639z"
    />
  </svg>
);

BrandLogo.propTypes = {
  className: PropTypes.string,
};

BrandLogo.defaultProps = {
  className: '',
};

export default BrandLogo;
