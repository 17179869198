const { languages } = require('../config/languages');

const removeTrailingSlash = path => (path === `/` ? path : path.replace(/\/$/, ``));

/**
 * Returns a path based on the locale received (e.g. `/it/pageName`, `/de/pageName`).
 * The default language will not be prefixed by `/en/`
 **/
const getLocalizedPath = (path, langCode) =>
  removeTrailingSlash(
    languages[langCode].isDefault ? path : `/${languages[langCode].locale}${path}`
  );

module.exports = {
  removeTrailingSlash,
  getLocalizedPath,
};
