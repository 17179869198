/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';

import Header from './header';
import Footer from './footer';
import useSiteMetadata from '../hooks/use-site-metadata';

import '../styles/main.scss';
import '../config/logrocket';

const Layout = ({ children, className, hasDarkNavigation }) => {
  const { menuLinks } = useSiteMetadata();

  return (
    <>
      <Header menuLinks={menuLinks} hasDarkBackground={hasDarkNavigation} />
      <div className={className}>{children}</div>
      <Footer></Footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Layout.defaultProps = {
  className: '',
};

export default Layout;
