import { useStaticQuery, graphql } from 'gatsby';

const useAppDownloadButtons = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          appDownloadButtons {
            type
            title
            downloadUrl
          }
        }
      }
      allFile(filter: {relativePath: {regex: "/app-download-button.+\\.svg$/"}}) {
        edges {
          node {
            publicURL
            relativePath
          }
        }
      }
    }
  `);
  return data.site.siteMetadata.appDownloadButtons.map(button => {
    return {
      ...button,
      publicUrl: data.allFile.edges.find(edge => edge.node.publicURL.includes(button.type)).node
        .publicURL,
    };
  });
};

export default useAppDownloadButtons;
